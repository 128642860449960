import produce from "immer";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  inappropriateDeleteRequest,
  inappropriateListRequest,
  inappropriateRelatedRequest,
  inappropriateRemoveRequest
} from "../../services/vbAPI/adapter";
import { inappropriateTypes } from "../types";

const initialState = {
  list: { requesting: true, data: null },
  delete: { requesting: true, data: null },
  remove: { requesting: true, data: null },
  related: { requesting: true, data: null },
};

/**
 * Inappropriate reducer for redux
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const inappropriateReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case inappropriateTypes.listRequest:
        draft.list.requesting = true;
        break;

      case inappropriateTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case inappropriateTypes.deleteRequest:
        draft.delete.requesting = true;
        break;

      case inappropriateTypes.deleteResponse:
        draft.delete.requesting = false;
        draft.delete.data = data;
        break;

      case inappropriateTypes.removeRequest:
        draft.remove.requesting = true;
        break;

      case inappropriateTypes.removeResponse:
        draft.remove.requesting = false;
        draft.remove.data = data;
        break;

      case inappropriateTypes.relatedRequest:
        draft.related.requesting = true;
        break;

      case inappropriateTypes.relatedResponse:
        draft.related.requesting = false;
        draft.related.data = data;
        break;

      default:
        break;
    }
  });

/**
 * Inappropriate Remove Request Action
 * @param {*} data
 * @returns
 */
export const inappropriateRemoveRequestAction = (data) => ({
  type: inappropriateTypes.removeRequest,
  data,
});

/**
 * inappropriate Remove Response action
 * @param {*} data
 * @returns
 */
export const inappropriateRemoveResponseAction = (data) => ({
  type: inappropriateTypes.removeResponse,
  data,
});

/**
 * Inappropriate Delete Request Action
 * @param {*} data
 * @returns
 */
export const inappropriateDeleteRequestAction = (data) => ({
  type: inappropriateTypes.deleteRequest,
  data,
});

/**
 * inappropriate Delete Response action
 * @param {*} data
 * @returns
 */
export const inappropriateDeleteResponseAction = (data) => ({
  type: inappropriateTypes.deleteResponse,
  data,
});

/**
 * Inappropriate Related Request Action
 * @param {*} data
 * @returns
 */
export const inappropriateRelatedRequestAction = (data) => ({
  type: inappropriateTypes.relatedRequest,
  data,
});

/**
 * inappropriate Related Response action
 * @param {*} data
 * @returns
 */
export const inappropriateRelatedResponseAction = (data) => ({
  type: inappropriateTypes.relatedResponse,
  data,
});

/**
 * Inappropriate List Request Action
 * @param {*} data
 * @returns
 */
export const inappropriateListRequestAction = (data) => ({
  type: inappropriateTypes.listRequest,
  data,
});

/**
 * inappropriate List Response action
 * @param {*} data
 * @returns
 */
export const inappropriateListResponseAction = (data) => ({
  type: inappropriateTypes.listResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** Inappropriate List Request Saga */
  inappropriateListRequest: function* ({ data }) {
    try {
      const response = yield call(inappropriateListRequest, data);
      yield put(inappropriateListResponseAction(response));
    } catch (error) {
      yield put(inappropriateListResponseAction(error));
    }
  },
  /** Inappropriate delete Request Saga */
  inappropriateDeleteRequest: function* ({ data }) {
    try {
      const response = yield call(inappropriateDeleteRequest, data);
      yield put(inappropriateDeleteResponseAction(response));
    } catch (error) {
      yield put(inappropriateDeleteResponseAction(error));
    }
  },
  /** Inappropriate Remove Request Saga */
  inappropriateRemoveRequest: function* ({ data }) {
    try {
      const response = yield call(inappropriateRemoveRequest, data);
      yield put(inappropriateRemoveResponseAction(response));
    } catch (error) {
      yield put(inappropriateRemoveResponseAction(error));
    }
  },

  /** Inappropriate Remove Request Saga */
  inappropriateRelatedRequest: function* ({ data }) {
    try {
      const response = yield call(inappropriateRelatedRequest, data);
      yield put(inappropriateRelatedResponseAction(response));
    } catch (error) {
      yield put(inappropriateRelatedResponseAction(error));
    }
  },
};

/** Inappropriate saga */
export function* inappropriateSaga() {
  yield takeLatest(
    inappropriateTypes.listRequest,
    sagas.inappropriateListRequest
  );
  yield takeLatest(
    inappropriateTypes.deleteRequest,
    sagas.inappropriateDeleteRequest
  );
  yield takeLatest(
    inappropriateTypes.removeRequest,
    sagas.inappropriateRemoveRequest
  );
  yield takeLatest(
    inappropriateTypes.relatedRequest,
    sagas.inappropriateRelatedRequest
  );
}
