import { combineReducers } from "redux";
import { adminReducer } from "./ducks/admins";
import { authReducer } from "./ducks/auth";
import { dashboardReducer } from "./ducks/dashboard";
import { inappropriateReducer } from "./ducks/inappropriate";
import { notificationReducer } from "./ducks/notifications";
import { templateReducer } from "./ducks/templates";
import { usersReducer } from "./ducks/users";
import { visionReducer } from "./ducks/visions";
import { checklistReducer } from "./ducks/checklist";
import { imageCategoryReducer } from "./ducks/imageCategory";
import { notificationActionsReducer } from "./ducks/notificationsActions";

/**
 * Combination of all the reducers
 * @param
 * @returns Reducer<CombinedState<{*}>
 */
export const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  admin: adminReducer,
  vision: visionReducer,
  inappropriate: inappropriateReducer,
  notification: notificationReducer,
  template: templateReducer,
  dashboard: dashboardReducer,
  checklist: checklistReducer,
  imageCategory: imageCategoryReducer,
  notificationActions: notificationActionsReducer,
});

export default rootReducer;
