import produce from "immer";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  adminsCreateRequest,
  adminsDeleteRequest,
  adminsListRequest,
  adminsUpdateRequest
} from "../../services/vbAPI/adapter";
import { adminTypes } from "../types";

/**
 * Initial State for the admin reducer
 */
const initialState = {
  list: { requesting: true, data: null },
  create: { requesting: true, data: null },
  update: { requesting: true, data: null },
  delete: { requesting: true, data: null },
};

/**
 * Admins reducer for redux.
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const adminReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case adminTypes.listRequest:
        draft.list.requesting = true;
        break;

      case adminTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case adminTypes.createRequest:
        draft.create.requesting = true;
        break;

      case adminTypes.createResponse:
        draft.create.requesting = false;
        draft.create.data = data;
        break;

      case adminTypes.updateRequest:
        draft.update.requesting = true;
        break;

      case adminTypes.updateResponse:
        draft.update.requesting = false;
        draft.update.data = data;
        break;

      case adminTypes.deleteRequest:
        draft.delete.requesting = true;
        break;

      case adminTypes.deleteResponse:
        draft.delete.requesting = false;
        draft.delete.data = data;
        break;

      default:
        break;
    }
  });

/**
 * Login Verify Request action
 * @param {*} data
 * @returns
 */
export const adminListRequestAction = (data) => ({
  type: adminTypes.listRequest,
  data,
});

/**
 * Login Verify Response action
 * @param {*} data
 * @returns
 */
export const adminListResponseAction = (data) => ({
  type: adminTypes.listResponse,
  data,
});

/**
 * Admin create Request action
 * @param {*} data
 * @returns
 */
export const adminCreateRequestAction = (data) => ({
  type: adminTypes.createRequest,
  data,
});

/**
 * Admin create Response action
 * @param {*} data
 * @returns
 */
export const adminCreateResponseAction = (data) => ({
  type: adminTypes.createResponse,
  data,
});

/**
 * Admin update Request action
 * @param {*} data
 * @returns
 */
export const adminUpdateRequestAction = (data) => ({
  type: adminTypes.updateRequest,
  data,
});

/**
 * Admin Update Response action
 * @param {*} data
 * @returns
 */
export const adminUpdateResponseAction = (data) => ({
  type: adminTypes.updateResponse,
  data,
});

/**
 * Admin Delete Request action
 * @param {*} data
 * @returns
 */
export const adminDeleteRequestAction = (data) => ({
  type: adminTypes.deleteRequest,
  data,
});

/**
 * Admin Delete Response action
 * @param {*} data
 * @returns
 */
export const adminDeleteResponseAction = (data) => ({
  type: adminTypes.deleteResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** Admin List Saga */
  adminListRequest: function* ({ data }) {
    try {
      const response = yield call(adminsListRequest, data);
      yield put(adminListResponseAction(response));
    } catch (error) {
      yield put(adminListResponseAction(error));
    }
  },
  /** Admin Create Saga */
  adminCreateRequest: function* ({ data }) {
    try {
      const response = yield call(adminsCreateRequest, data);
      yield put(adminCreateResponseAction(response));
    } catch (error) {
      yield put(adminCreateResponseAction(error));
    }
  },
  /** Admin update Saga */
  adminUpdateRequest: function* ({ data }) {
    try {
      const response = yield call(adminsUpdateRequest, data);
      yield put(adminUpdateResponseAction(response));
    } catch (error) {
      yield put(adminUpdateResponseAction(error));
    }
  },
  /** Admin Delete Saga */
  adminDeleteRequest: function* ({ data }) {
    try {
      const response = yield call(adminsDeleteRequest, data);
      yield put(adminDeleteResponseAction(response));
    } catch (error) {
      yield put(adminDeleteResponseAction(error));
    }
  },
};

/** Admins saga */
export function* adminSaga() {
  yield takeLeading(adminTypes.listRequest, sagas.adminListRequest);
  yield takeLeading(adminTypes.createRequest, sagas.adminCreateRequest);
  yield takeLeading(adminTypes.updateRequest, sagas.adminUpdateRequest);
  yield takeLeading(adminTypes.deleteRequest, sagas.adminDeleteRequest);
}
