import produce from "immer";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  usersListRequest,
  usersUpdateRequest
} from "../../services/vbAPI/adapter";
import { userTypes } from "../types";

const initialState = {
  list: { requesting: true, data: null },
  update: { requesting: true, data: null },
};

/**
 * Users reducer for redux
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const usersReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case userTypes.listRequest:
        draft.list.requesting = true;
        break;

      case userTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case userTypes.updateRequest:
        draft.update.requesting = true;
        break;

      case userTypes.updateResponse:
        draft.update.requesting = false;
        draft.update.data = data;
        break;

      default:
        break;
    }
  });

/**
 * Login Verify Request action
 * @param {*} data
 * @returns
 */
export const userListRequestAction = (data) => ({
  type: userTypes.listRequest,
  data,
});

/**
 * Login Verify Response action
 * @param {*} data
 * @returns
 */
export const userListResponseAction = (data) => ({
  type: userTypes.listResponse,
  data,
});

/**
 * User Update Request action
 * @param {*} data
 * @returns
 */
export const userUpdateRequestAction = (data) => ({
  type: userTypes.updateRequest,
  data,
});

/**
 * User Update Response action
 * @param {*} data
 * @returns
 */
export const userUpdateResponseAction = (data) => ({
  type: userTypes.updateResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** List User Request Saga */
  userListRequest: function* ({ data }) {
    try {
      const response = yield call(usersListRequest, data);
      yield put(userListResponseAction(response));
    } catch (error) {
      yield put(userListResponseAction(error));
    }
  },

  /** Update User Request Saga */
  userUpdateRequest: function* ({ data }) {
    try {
      const response = yield call(usersUpdateRequest, data);
      yield put(userUpdateResponseAction(response));
    } catch (error) {
      yield put(userUpdateResponseAction(error));
    }
  },
};

/**
 * users saga
 */
export function* usersSaga() {
  yield takeLatest(userTypes.listRequest, sagas.userListRequest);
  yield takeLatest(userTypes.updateRequest, sagas.userUpdateRequest);
}
