import React from "react";
import Status from "./index";

/**
 * @param {import("antd").ResultProps} props
 */
export default ({
  title = "Error 404",
  subTitle = "Sorry, the page you visited does not exist.",
  ...props
}) => {
  return <Status status="404" title={title} subTitle={subTitle} />;
};
