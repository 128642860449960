/**
 * Login action/reducer types
 */
export const authTypes = {
  loginRequest: "auth/login/request",
  loginResponse: "auth/login/response",
  verifyRequest: "auth/verify/request",
  verifyResponse: "auth/verify/response",
};

/**
 * User action/reducer types
 */
export const userTypes = {
  listRequest: "users/list/request",
  listResponse: "users/list/response",
  updateRequest: "users/update/request",
  updateResponse: "users/update/response",
};

/**
 * Admins action/reducer types
 */
export const adminTypes = {
  listRequest: "admin/list/request",
  listResponse: "admin/list/response",
  createRequest: "admin/create/request",
  createResponse: "admin/create/response",
  updateRequest: "admin/update/request",
  updateResponse: "admin/update/response",
  deleteRequest: "admin/delete/request",
  deleteResponse: "admin/delete/response",
};

/**
 * Vision action/reducer types
 */
export const visionTypes = {
  listRequest: "vision/list/request",
  listResponse: "vision/list/response",
  updateRequest: "vision/update/request",
  updateResponse: "vision/update/response",
};

/**
 * Inappropriate Types
 */
export const inappropriateTypes = {
  listRequest: "inappropriate/list/request",
  listResponse: "inappropriate/list/response",
  deleteRequest: "inappropriate/delete/request",
  deleteResponse: "inappropriate/delete/response",
  removeRequest: "inappropriate/remove/request",
  removeResponse: "inappropriate/remove/response",
  relatedRequest: "inappropriate/related/request",
  relatedResponse: "inappropriate/related/response",
};

/**
 * Inappropriate Types
 */
export const notificationTypes = {
  listRequest: "notification/list/request",
  listResponse: "notification/list/response",
  createRequest: "notification/create/request",
  createResponse: "notification/create/response",
  updateRequest: "notification/update/request",
  updateResponse: "notification/update/response",
  deleteRequest: "notification/delete/request",
  deleteResponse: "notification/delete/response",
  inappropriate: "notification/inappro/request",
  inappropriateResponse: "notification/inappro/response",
};

/**
 * Inappropriate Types
 */
export const notificationActionsTypes = {
  listRequest: "notAct/list/request",
  listResponse: "notAct/list/response",
  createRequest: "notAct/create/request",
  createResponse: "notAct/create/response",
  updateRequest: "notAct/update/request",
  updateResponse: "notAct/update/response",
  deleteRequest: "notAct/delete/request",
  deleteResponse: "notAct/delete/response",
};

/**
 * Inappropriate Types
 */
export const checklistTypes = {
  listRequest: "checklist/list/request",
  listResponse: "checklist/list/response",
  createRequest: "checklist/create/request",
  createResponse: "checklist/create/response",
  updateRequest: "checklist/update/request",
  updateResponse: "checklist/update/response",
  deleteRequest: "checklist/delete/request",
  deleteResponse: "checklist/delete/response",
};

/**
 * template Types
 */
export const templateTypes = {
  listRequest: "template/list/request",
  listResponse: "template/list/response",
  createRequest: "template/create/request",
  createResponse: "template/create/response",
  detailsRequest: "template/details/request",
  detailsResponse: "template/details/response",
  updateRequest: "template/update/request",
  updateResponse: "template/update/response",
  deleteRequest: "template/delete/request",
  deleteResponse: "template/delete/response",
};

/**
 * Dashboard Types
 */
export const dashboardTypes = {
  dataRequest: "dashboard/data/request",
  dataResponse: "dashboard/data/response",
  usersChartRequest: "dashboard/userChart/request",
  usersChartResponse: "dashboard/userChart/response",
};
/**
 * Image categories types
 */
export const imageCategoryTypes = {
  listRequest: "imgcat/list/request",
  listResponse: "imgcat/list/response",
  createRequest: "imgcat/create/request",
  createResponse: "imgcat/create/response",
  updateRequest: "imgcat/update/request",
  updateResponse: "imgcat/update/response",
  deleteRequest: "imgcat/delete/request",
  deleteResponse: "imgcat/delete/response",
};
