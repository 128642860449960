import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Avatar, Col, Dropdown, Image, Layout, Menu, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/vbLogo-Onboard.png";
import { onLogOut } from "../../../helpers/auth";

/**
 * Authenticated user menu is header right section.
 */
const userMenu = () => {
  return (
    <Menu
      style={{ minWidth: "150px" }}
      items={[
        {
          label: <Link to={"/profile"}>Profile</Link>,
        },
        {
          label: "Log Out",
          onClick: onLogOut,
        },
      ]}
    />
  );
};

/**
 * Header component for default layout component.
 */
const Header = ({ onToggleSidebar, isSidebarCollapsed }) => {
  const user = useSelector((state) => state.auth.verify);

  return (
    <Layout.Header
      style={{
        background: "#fff",
        borderBottom: "1px solid #eee",
        padding: "0px 21px",
      }}
    >
      <Row align="middle" justify="space-between">
        <Col>
          <Row align="middle">
            <Col style={{ paddingRight: 10, fontSize: 17 }}>
              {isSidebarCollapsed ? (
                <MenuUnfoldOutlined onClick={onToggleSidebar} />
              ) : (
                <MenuFoldOutlined onClick={onToggleSidebar} />
              )}
            </Col>
            <Col>
              <Image
                src={logo}
                preview={false}
                style={{
                  height: "auto",
                  maxWidth: "180px",
                }}
                alt="logo"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Dropdown
            overlay={userMenu}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <Avatar
              shape="circle"
              size="large"
              style={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                cursor: "pointer",
              }}
              src={user?.data?.body?.avatar?.url}
              icon={<UserOutlined />}
            />
          </Dropdown>
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
