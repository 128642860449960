/* eslint-disable no-useless-escape */

/**
 * Get Current host origin
 * @returns {String}
 */
export const getOrigin = () => {
  const { protocol, host, hostname } = window.location;
  return `${protocol}//${host || hostname}`;
};

/**
 * Check from navigator that user is online or not.
 * @returns boolean
 */
export const checkOnLine = () => {
  if (window.navigator) {
    return !!(window.navigator.onLine && window.navigator.onLine === true);
  }
  return true;
};

/**
 * Get Parameter from url using name as key
 * @param {*} n
 * @returns
 */
export const getParameterByName = (n) => {
  const name = n.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

/**
 * Select local/session Storage Data using key
 * @param string key
 * @param boolean session
 * @param boolean parsed
 * @returns
 */
export const storageGet = (key, parsed = false, session = false) => {
  if (typeof key !== "string" || typeof session !== "boolean")
    return [null, false];

  try {
    const storage = session ? window.sessionStorage : window.localStorage;
    let data = storage.getItem(key);
    if (parsed && data) data = JSON.parse(data);
    return [data, true];
  } catch (error) {
    return [error, false];
  }
};

/**
 * Set local/session Storage Data using key
 * @param string key
 * @param boolean session
 * @returns
 */
export const storageSet = (key, value, session = false, stringify = false) => {
  if (
    typeof key !== "string" ||
    typeof session !== "boolean" ||
    typeof stringify !== "boolean"
  )
    return false;

  try {
    const data = stringify ? JSON.stringify(value) : value;
    const storage = session ? window.sessionStorage : window.localStorage;
    storage.setItem(key, data);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Remove local/session Storage Item using key
 * @param string key
 * @param boolean session
 * @returns
 */
export const storageRemove = (key, session = false) => {
  if (typeof key !== "string" || typeof session !== "boolean") return false;
  try {
    const storage = session ? window.sessionStorage : window.localStorage;
    storage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Check if it is browser or not
 */
export const isBrowser = typeof window !== "undefined";

/**
 * remove a cookie from browser
 * Note - changes may work post reload
 * @param {*} name
 */
export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  return true;
};

/**
 * Pass name value and option to addd a cookie
 * @param {String} name
 * @param {String} value
 * @param {Object} options
 * @returns
 */
export const setCookie = (name, value, options) => {
  if (!isBrowser) return;

  const optionsWithDefaults = {
    days: 7,
    path: "/",
    ...options,
  };

  const expires = new Date(
    Date.now() + optionsWithDefaults.days * 864e5
  ).toUTCString();

  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    expires +
    "; path=" +
    optionsWithDefaults.path;
};

/**
 * Get cookie by name also you can pass default value for undefined ones.
 * @param {String} name
 * @param {any} initialValue
 * @returns
 */
export const getCookie = (name, initialValue = "") => {
  return (
    (isBrowser &&
      document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=");
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, "")) ||
    initialValue
  );
};

/**
 * Check if given string is a valid url.
 * @param {*} string
 * @returns
 */
export const isValidHttpUrl = (string) => {
  try {
    const url = new URL(string);
    return (
      (url.protocol === "http:" || url.protocol === "https:") &&
      (url.href === string || url.origin === string)
    );
  } catch (_) {
    return false;
  }
};

/**
 *
 * @param {String} value
 * @param {HTMLInputElement} dom
 * @returns
 */
export const copyToClipboard = async (value, dom = null) => {
  const clipboard = navigator?.clipboard;
  try {
    if (clipboard !== undefined && clipboard !== "undefined") {
      if (dom) {
        dom.select();
        dom.setSelectionRange(0, value.length);
      }
      return navigator.clipboard
        .writeText(value)
        .then(() => true)
        .catch(() => false);
    }
    if (document.execCommand) {
      let el = dom;
      if (!el) {
        el = document.createElement("input");
        el.value = value;
        document.body.append(el);
      }

      el.select();
      el.setSelectionRange(0, value.length);
      if (document?.execCommand("copy")) {
        el.remove();
        return true;
      }
      el.remove();
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};
