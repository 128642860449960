import produce from "immer";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  notificationCreateRequest,
  notificationDeleteRequest,
  notificationListRequest,
  notificationUpdateRequest,
  notificationInappropriateRequest,
} from "../../services/vbAPI/adapter";
import { notificationTypes } from "../types";

/**
 * Initial State for the admin reducer
 */
const initialState = {
  list: { requesting: true, data: null },
  create: { requesting: true, data: null },
  update: { requesting: true, data: null },
  delete: { requesting: true, data: null },
  inappropriate: { requesting: true, data: null },
};

/**
 * Notifications reducer for redux.
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const notificationReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case notificationTypes.listRequest:
        draft.list.requesting = true;
        break;

      case notificationTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case notificationTypes.createRequest:
        draft.create.requesting = true;
        break;

      case notificationTypes.createResponse:
        draft.create.requesting = false;
        draft.create.data = data;
        break;

      case notificationTypes.updateRequest:
        draft.update.requesting = true;
        break;

      case notificationTypes.updateResponse:
        draft.update.requesting = false;
        draft.update.data = data;
        break;

      case notificationTypes.deleteRequest:
        draft.delete.requesting = true;
        break;

      case notificationTypes.deleteResponse:
        draft.delete.requesting = false;
        draft.delete.data = data;
        break;

      case notificationTypes.inappropriate:
        draft.inappropriate.requesting = true;
        break;

      case notificationTypes.inappropriateResponse:
        draft.inappropriate.requesting = false;
        draft.inappropriate.data = data;
        break;

      default:
        break;
    }
  });

/**
 * Notification List Request action
 * @param {*} data
 * @returns
 */
export const notificationListRequestAction = (data) => ({
  type: notificationTypes.listRequest,
  data,
});

/**
 * Notification List Response action
 * @param {*} data
 * @returns
 */
export const notificationListResponseAction = (data) => ({
  type: notificationTypes.listResponse,
  data,
});

/**
 * Notification create Request action
 * @param {*} data
 * @returns
 */
export const notificationCreateRequestAction = (data) => ({
  type: notificationTypes.createRequest,
  data,
});

/**
 * Notification create Response action
 * @param {*} data
 * @returns
 */
export const notificationCreateResponseAction = (data) => ({
  type: notificationTypes.createResponse,
  data,
});

/**
 * Notification update Request action
 * @param {*} data
 * @returns
 */
export const notificationUpdateRequestAction = (data) => ({
  type: notificationTypes.updateRequest,
  data,
});

/**
 * Notification Update Response action
 * @param {*} data
 * @returns
 */
export const notificationUpdateResponseAction = (data) => ({
  type: notificationTypes.updateResponse,
  data,
});

/**
 * Login Delete Request action
 * @param {*} data
 * @returns
 */
export const notificationDeleteRequestAction = (data) => ({
  type: notificationTypes.deleteRequest,
  data,
});

/**
 * Login Delete Response action
 * @param {*} data
 * @returns
 */
export const notificationDeleteResponseAction = (data) => ({
  type: notificationTypes.deleteResponse,
  data,
});

/**
 * Login Delete Request action
 * @param {*} data
 * @returns
 */
export const inappropriateRequestAction = (data) => ({
  type: notificationTypes.inappropriate,
  data,
});

/**
 * Login Delete Response action
 * @param {*} data
 * @returns
 */
export const inappropriateResponseAction = (data) => ({
  type: notificationTypes.inappropriateResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** Notification List Saga */
  notificationListRequest: function* ({ data }) {
    try {
      const response = yield call(notificationListRequest, data);
      yield put(notificationListResponseAction(response));
    } catch (error) {
      yield put(notificationListResponseAction(error));
    }
  },
  /** Notification Create Saga */
  notificationCreateRequest: function* ({ data }) {
    try {
      const response = yield call(notificationCreateRequest, data);
      yield put(notificationCreateResponseAction(response));
    } catch (error) {
      yield put(notificationCreateResponseAction(error));
    }
  },
  /** Notification update Saga */
  notificationUpdateRequest: function* ({ data }) {
    try {
      const response = yield call(notificationUpdateRequest, data);
      yield put(notificationUpdateResponseAction(response));
    } catch (error) {
      yield put(notificationUpdateResponseAction(error));
    }
  },
  /** Notification List Saga */
  notificationDeleteRequest: function* ({ data }) {
    try {
      const response = yield call(notificationDeleteRequest, data);
      yield put(notificationDeleteResponseAction(response));
    } catch (error) {
      yield put(notificationDeleteResponseAction(error));
    }
  },

  /** Notification List Saga */
  inappropriateRequest: function* ({ data }) {
    try {
      const response = yield call(notificationInappropriateRequest, data);
      yield put(inappropriateResponseAction(response));
    } catch (error) {
      yield put(inappropriateResponseAction(error));
    }
  },
};

/** Admins saga */
export function* notificationSaga() {
  yield takeLeading(
    notificationTypes.listRequest,
    sagas.notificationListRequest
  );
  yield takeLeading(
    notificationTypes.createRequest,
    sagas.notificationCreateRequest
  );
  yield takeLeading(
    notificationTypes.updateRequest,
    sagas.notificationUpdateRequest
  );
  yield takeLeading(
    notificationTypes.deleteRequest,
    sagas.notificationDeleteRequest
  );

  yield takeLeading(
    notificationTypes.inappropriate,
    sagas.inappropriateRequest
  );
}
