import service from "./index";

/**
 * Make login Verify Api call
 * @param {*} data
 * @returns
 */
export const loginVerify = async (data) => {
  const response = await service.http.get(service.urls.auth.verify, {
    params: {
      identifier: "ADMIN",
    },
  });
  return response;
};

/**
 * Make login request by user email and password.
 * @param {*} data
 * @returns
 */
export const loginRequest = async (data) => {
  const response = await service.http.post(service.urls.auth.login, data, {
    params: {
      identifier: "ADMIN",
    },
  });
  return response;
};

/**
 * Make users list api call.
 * @param {*} data
 * @returns
 */
export const usersListRequest = async (data) => {
  const { version, users } = service.urls;
  const url = `${version}${users.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make users update api call.
 * @param {*} data
 * @returns
 */
export const usersUpdateRequest = async (data) => {
  const { version, users } = service.urls;
  const url = `${version}${users.update.replace(":id", data.id)}`;
  const response = await service.http.put(url, data);
  return response;
};

/**
 * Make admins list api call.
 * @param {*} data
 * @returns
 */
export const adminsListRequest = async (data) => {
  const { version, admins } = service.urls;
  const url = `${version}${admins.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make admins create api call.
 * @param {*} data
 * @returns
 */
export const adminsCreateRequest = async (data) => {
  const { version, admins } = service.urls;
  const url = `${version}${admins.create}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make admins update api call.
 * @param {*} data
 * @returns
 */
export const adminsUpdateRequest = async (data) => {
  const { version, admins } = service.urls;
  const url = `${version}${admins.details.replace(":id", data.id)}`;
  const response = await service.http.put(url, data);
  return response;
};

/**
 * Make admins update api call.
 * @param {*} data
 * @returns
 */
export const adminsDeleteRequest = async (data) => {
  const { version, admins } = service.urls;
  const url = `${version}${admins.details.replace(":id", data.id)}`;
  const response = await service.http.delete(url);
  return response;
};

/**
 * Make visions list api call.
 * @param {*} data
 * @returns
 */
export const visionsListRequest = async (data) => {
  const { version, visions } = service.urls;
  const url = `${version}${visions.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make visions list api call.
 * @param {*} data
 * @returns
 */
export const visionsUpdateRequest = async (data) => {
  const { version, visions } = service.urls;
  const url = `${version}${visions.update.replace(":id", data.id)}`;
  const response = await service.http.put(url, data);
  return response;
};

/**
 * Make users update api call.
 * @param {*} data
 * @returns
 */
export const visionsDeleteRequest = async (data) => {
  const { version, visions } = service.urls;
  const url = `${version}${visions.update.replace(":id", data.id)}`;
  const response = await service.http.delete(url);
  return response;
};

/**
 * Make inappropriate list api call.
 * @param {*} data
 * @returns
 */
export const inappropriateListRequest = async (data) => {
  const { version, inappropriate } = service.urls;
  const url = `${version}${inappropriate.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make inappropriate action api call.
 * @param {*} data
 * @returns
 */
export const inappropriateRemoveRequest = async (data) => {
  const { version, inappropriate } = service.urls;
  const url = `${version}${inappropriate.remove.replace(":id", data.id)}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make inappropriate delete api call.
 * @param {*} data
 * @returns
 */
export const inappropriateDeleteRequest = async (data) => {
  const { version, inappropriate } = service.urls;
  const url = `${version}${inappropriate.details.replace(":id", data.id)}`;
  const response = await service.http.delete(url);
  return response;
};

/**
 * Make inappropriate related details api call.
 * @param {*} data
 * @returns
 */
export const inappropriateRelatedRequest = async (data) => {
  const { version, inappropriate } = service.urls;
  const url = `${version}${inappropriate.related
    .replace(":rid", data.referenceId)
    .replace(":t", data.type)}`;
  const response = await service.http.get(url);
  return response;
};

/**
 * Upload image request
 * @param {*} data
 * @returns
 */
export const uploadAvatarImageRequest = async (data, config = {}) => {
  const { version, images } = service.urls;
  const url = `${version}${images.avatar}`;
  const response = await service.http.post(url, data, config);
  return response;
};

/**
 * Upload image request
 * @param {*} data
 * @returns
 */
export const removeAvatarImageRequest = async (data, config = {}) => {
  const { version, images } = service.urls;
  const url = `${version}${images.avatarDelete}`;
  const response = await service.http.post(url, data, config);
  return response;
};

/**
 * Make notification update api call.
 * @param {*} data
 * @returns
 */
export const notificationInappropriateRequest = async (data) => {
  const { version } = service.urls;
  const url = `${version}/notification/inappropriate/${data.id}`;
  const response = await service.http.get(url);
  return response;
};

/**
 * Make Notifications list api call.
 * @param {*} data
 * @returns
 */
export const notificationListRequest = async (data) => {
  const { version, notification } = service.urls;
  const url = `${version}${notification.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make notification create api call.
 * @param {*} data
 * @returns
 */
export const notificationCreateRequest = async (data) => {
  const { version, notification } = service.urls;
  const url = `${version}${notification.create}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make notification update api call.
 * @param {*} data
 * @returns
 */
export const notificationUpdateRequest = async (data) => {
  const { version, notification } = service.urls;
  const url = `${version}${notification.details.replace(":id", data.id)}`;
  const response = await service.http.put(url, data);
  return response;
};

/**
 * Make notification delete api call.
 * @param {*} data
 * @returns
 */
export const notificationDeleteRequest = async (data) => {
  const { version, notification } = service.urls;
  const url = `${version}${notification.details.replace(":id", data.id)}`;
  const response = await service.http.delete(url);
  return response;
};

/**
 * Make Notifications list api call.
 * @param {*} data
 * @returns
 */
export const notificationActionsListRequest = async (data) => {
  const { version, notificationActions } = service.urls;
  const url = `${version}${notificationActions.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make notification create api call.
 * @param {*} data
 * @returns
 */
export const notificationActionsCreateRequest = async (data) => {
  const { version, notificationActions } = service.urls;
  const url = `${version}${notificationActions.create}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make notification update api call.
 * @param {*} data
 * @returns
 */
export const notificationActionsUpdateRequest = async (data) => {
  const { version, notificationActions } = service.urls;
  const url = `${version}${notificationActions.details.replace(
    ":id",
    data.id
  )}`;
  const response = await service.http.put(url, data);
  return response;
};

/**
 * Make notification delete api call.
 * @param {*} data
 * @returns
 */
export const notificationActionsDeleteRequest = async (data) => {
  const { version, notificationActions } = service.urls;
  const url = `${version}${notificationActions.details.replace(
    ":id",
    data.id
  )}`;
  const response = await service.http.delete(url);
  return response;
};

/**
 * Make templates list api call.
 * @param {*} data
 * @returns
 */
export const templateListRequest = async (data) => {
  const { version, template } = service.urls;
  const url = `${version}${template.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make template create api call.
 * @param {*} data
 * @returns
 */
export const templateCreateRequest = async (data) => {
  const { version, template } = service.urls;
  const url = `${version}${template.create}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make template details api call.
 * @param {*} data
 * @returns
 */
export const templateDetailsRequest = async (data) => {
  const { version, template } = service.urls;
  const url = `${version}${template.details.replace(":id", data.id)}`;
  const response = await service.http.get(url);
  return response;
};

/**
 * Make template update api call.
 * @param {*} data
 * @returns
 */
export const templateUpdateRequest = async (data) => {
  const { version, template } = service.urls;
  const url = `${version}${template.details.replace(":id", data.id)}`;
  const response = await service.http.put(url, data);
  return response;
};

/**
 * Make template delete api call.
 * @param {*} data
 * @returns
 */
export const templateDeleteRequest = async (data) => {
  const { version, template } = service.urls;
  const url = `${version}${template.details.replace(":id", data.id)}`;
  const response = await service.http.delete(url);
  return response;
};

/**
 * Dashboard one time data call api.
 * @param {*} data
 * @returns
 */
export const dashboardDataRequest = async (data) => {
  const { version, dashboard } = service.urls;
  const response = await service.http.get(`${version}${dashboard.index}`);
  return response;
};

/**
 * Make Users chart request in dashboard.
 * @param {*} data
 * @returns
 */
export const dashboardUsersChartRequest = async (data) => {
  const { version, dashboard } = service.urls;
  const url = `${version}${dashboard.userChart}`;
  const response = await service.http.get(url);
  return response;
};

/**
 * Make Checklist list api call.
 * @param {*} data
 * @returns
 */
export const checklistListRequest = async (data) => {
  const { version, checklist } = service.urls;
  const url = `${version}${checklist.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make checklist create api call.
 * @param {*} data
 * @returns
 */
export const checklistCreateRequest = async (data) => {
  const { version, checklist } = service.urls;
  const url = `${version}${checklist.create}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make checklist update api call.
 * @param {*} data
 * @returns
 */
export const checklistUpdateRequest = async (data) => {
  const { version, checklist } = service.urls;
  const url = `${version}${checklist.details.replace(":id", data.id)}`;
  const response = await service.http.put(url, data);
  return response;
};

/**
 * Make checklist delete api call.
 * @param {*} data
 * @returns
 */
export const checklistDeleteRequest = async (data) => {
  const { version, checklist } = service.urls;
  const url = `${version}${checklist.details.replace(":id", data.id)}`;
  const response = await service.http.delete(url);
  return response;
};

/**
 * Make imageCategory list api call.
 * @param {*} data
 * @returns
 */
export const imageCategoryListRequest = async (data) => {
  const { version, imageCategory } = service.urls;
  const url = `${version}${imageCategory.list}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make imageCategory create api call.
 * @param {*} data
 * @returns
 */
export const imageCategoryCreateRequest = async (data) => {
  const { version, imageCategory } = service.urls;
  const url = `${version}${imageCategory.create}`;
  const response = await service.http.post(url, data);
  return response;
};

/**
 * Make imageCategory update api call.
 * @param {*} data
 * @returns
 */
export const imageCategoryUpdateRequest = async (data) => {
  const { version, imageCategory } = service.urls;
  const url = `${version}${imageCategory.details.replace(":id", data.id)}`;
  const response = await service.http.put(url, data);
  return response;
};

/**
 * Make imageCategory delete api call.
 * @param {*} data
 * @returns
 */
export const imageCategoryDeleteRequest = async (data) => {
  const { version, imageCategory } = service.urls;
  const url = `${version}${imageCategory.details.replace(":id", data.id)}`;
  const response = await service.http.delete(url);
  return response;
};
