import produce from "immer";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  templateCreateRequest,
  templateDeleteRequest,
  templateDetailsRequest,
  templateListRequest,
  templateUpdateRequest
} from "../../services/vbAPI/adapter";
import { templateTypes } from "../types";

/**
 * Initial State for the admin reducer
 */
const initialState = {
  list: { requesting: true, data: null },
  details: { requesting: true, data: null },
  create: { requesting: true, data: null },
  update: { requesting: true, data: null },
  delete: { requesting: true, data: null },
};

/**
 * Email template reducer for redux.
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const templateReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case templateTypes.listRequest:
        draft.list.requesting = true;
        break;

      case templateTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case templateTypes.createRequest:
        draft.create.requesting = true;
        break;

      case templateTypes.createResponse:
        draft.create.requesting = false;
        draft.create.data = data;
        break;

      case templateTypes.updateRequest:
        draft.update.requesting = true;
        break;

      case templateTypes.updateResponse:
        draft.update.requesting = false;
        draft.update.data = data;
        break;

      case templateTypes.detailsRequest:
        draft.details.requesting = true;
        break;

      case templateTypes.detailsResponse:
        draft.details.requesting = false;
        draft.details.data = data;
        break;

      case templateTypes.deleteRequest:
        draft.delete.requesting = true;
        break;

      case templateTypes.deleteResponse:
        draft.delete.requesting = false;
        draft.delete.data = data;
        break;

      default:
        break;
    }
  });

/**
 * Email template List Request action
 * @param {*} data
 * @returns
 */
export const templateListRequestAction = (data) => ({
  type: templateTypes.listRequest,
  data,
});

/**
 * Email template List Response action
 * @param {*} data
 * @returns
 */
export const templateListResponseAction = (data) => ({
  type: templateTypes.listResponse,
  data,
});

/**
 * Email template create Request action
 * @param {*} data
 * @returns
 */
export const templateCreateRequestAction = (data) => ({
  type: templateTypes.createRequest,
  data,
});

/**
 * Email template create Response action
 * @param {*} data
 * @returns
 */
export const templateCreateResponseAction = (data) => ({
  type: templateTypes.createResponse,
  data,
});

/**
 * Email template details Request action
 * @param {*} data
 * @returns
 */
export const templateDetailsRequestAction = (data) => ({
  type: templateTypes.detailsRequest,
  data,
});

/**
 * Email template details Response action
 * @param {*} data
 * @returns
 */
export const templateDetailsResponseAction = (data) => ({
  type: templateTypes.detailsResponse,
  data,
});

/**
 * Email template update Request action
 * @param {*} data
 * @returns
 */
export const templateUpdateRequestAction = (data) => ({
  type: templateTypes.updateRequest,
  data,
});

/**
 * Email template Update Response action
 * @param {*} data
 * @returns
 */
export const templateUpdateResponseAction = (data) => ({
  type: templateTypes.updateResponse,
  data,
});

/**
 * Email template Delete Request action
 * @param {*} data
 * @returns
 */
export const templateDeleteRequestAction = (data) => ({
  type: templateTypes.deleteRequest,
  data,
});

/**
 * Email template Delete Response action
 * @param {*} data
 * @returns
 */
export const templateDeleteResponseAction = (data) => ({
  type: templateTypes.deleteResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** Email template List Saga */
  templateListRequest: function* ({ data }) {
    try {
      const response = yield call(templateListRequest, data);
      yield put(templateListResponseAction(response));
    } catch (error) {
      yield put(templateListResponseAction(error));
    }
  },
  /** Notification Create Saga */
  templateCreateRequest: function* ({ data }) {
    try {
      const response = yield call(templateCreateRequest, data);
      yield put(templateCreateResponseAction(response));
    } catch (error) {
      yield put(templateCreateResponseAction(error));
    }
  },
  /** Email template details Saga */
  templateDetailsRequest: function* ({ data }) {
    try {
      const response = yield call(templateDetailsRequest, data);
      yield put(templateDetailsResponseAction(response));
    } catch (error) {
      yield put(templateDetailsResponseAction(error));
    }
  },
  /** Email template update Saga */
  templateUpdateRequest: function* ({ data }) {
    try {
      const response = yield call(templateUpdateRequest, data);
      yield put(templateUpdateResponseAction(response));
    } catch (error) {
      yield put(templateUpdateResponseAction(error));
    }
  },
  /** Email Template delete Saga */
  templateDeleteRequest: function* ({ data }) {
    try {
      const response = yield call(templateDeleteRequest, data);
      yield put(templateDeleteResponseAction(response));
    } catch (error) {
      yield put(templateDeleteResponseAction(error));
    }
  },
};

/** Email template saga */
export function* templateSaga() {
  yield takeLeading(templateTypes.listRequest, sagas.templateListRequest);
  yield takeLeading(templateTypes.detailsRequest, sagas.templateDetailsRequest);
  yield takeLeading(templateTypes.createRequest, sagas.templateCreateRequest);
  yield takeLeading(templateTypes.updateRequest, sagas.templateUpdateRequest);
  yield takeLeading(templateTypes.deleteRequest, sagas.templateDeleteRequest);
}
