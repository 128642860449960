export const breadcrumbNameMap = {
  "/": "Dashboard",
  "/users": "Users",
  "/admins": "Admins",
  "/inappropriate": "Inappropriate",
  "/visions": "Visions",
  "/image-categories": "Image Categories",
  "/notifications-actions": "Notification Actions",
  "/notifications": "Notifications",
  "/templates": "Templates",
  "/templates/:id": "Edit",
  "/templates/add": "Add",
};
