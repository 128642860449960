/**
 * Get Layout sidebar path to key
 * @param {string} match
 */
export const getLayoutSidebarPathToKey = (match) => {
  let $selected = "1";
  switch (match) {
    case "users":
      $selected = "2";
      break;

    case "admins":
      $selected = "3";
      break;

    case "visions":
      $selected = "4";
      break;

    case "inappropriate":
      $selected = "5";
      break;

    case "notifications":
      $selected = "6";
      break;

    case "templates":
      $selected = "7";
      break;

    case "checklist":
      $selected = "8";
      break;

    case "image-categories":
      $selected = "9";
      break;

    case "notifications-actions":
      $selected = "10";
      break;

    default:
      $selected = "1";
      break;
  }
  return $selected;
};

/**
 * Get sidebar navigation path by key
 * @param {string} key
 */
export const getLayoutSidebarNavigation = (key) => {
  let value = "";
  switch (Number(key)) {
    case 1:
      value = "/";
      break;
    case 2:
      value = "/users";
      break;
    case 3:
      value = "/admins";
      break;
    case 4:
      value = "/visions";
      break;
    case 5:
      value = "/inappropriate";
      break;
    case 6:
      value = "/notifications";
      break;
    case 7:
      value = "/templates";
      break;
    case 8:
      value = "/checklist";
      break;
    case 9:
      value = "/image-categories";
      break;
    case 10:
      value = "/notifications-actions";
      break;
    default:
      value = "";
      break;
  }
  return value;
};
