import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { breadcrumbNameMap } from "../../../constants/breadcrumbs";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = useMemo(() => {
    const crumbs = pathSnippets.map((_, index) => {
      const sliced = pathSnippets.slice(0, index + 1);
      const url = `/${sliced.join("/")}`;
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        </Breadcrumb.Item>
      );
    });

    if (crumbs.length) {
      crumbs.unshift(
        <Breadcrumb.Item key="home">
          <Link to="/">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
      );
    }
    return crumbs;
  }, [pathSnippets]);

  return (
    <div style={{ width: "100%", padding: "0px 0 20px 0" }}>
      <Breadcrumb>{extraBreadcrumbItems}</Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
