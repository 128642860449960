import { AUTH_LOCAL_STORAGE_KEY, VB_API_BASE_URL } from "../../constants";
import { getCookie } from "../../utils/io";
import { HttpService } from "../http/index";

/**
 * HTTP client side possible errors.
 */
const API_ERRORS = {
  timeout: {
    fault: {
      message: "Request Timeout",
      description: "Request took longer than expected, Try Again!",
    },
  },
  common: (error, message) => {
    return {
      fault: {
        message: error?.message || message || "Unknown Error Occurred.",
        description:
          error?.message || message || "Unknown Error Occurred. Try Again!",
      },
    };
  },
};

/**
 * Resolver Vb Api response Error or Success
 * @param {*} response
 */
export const vbApiResponseResolver = (data, raw = null) => {
  let error = false;

  /**
   * @Note We will revalidate the error condition once again according to cases
   */
  // if (raw && raw.response && raw.response.status === 500) {
  //   error = true;
  //   // if content type is not json mean some unhandled error occurred.
  //   if (
  //     raw.response.headers["content-type"].toLowerCase() !== "application/json"
  //   ) {
  //     return [true, API_ERRORS.common({}, null)];
  //   }
  // }

  // @TODO - ONCE WE WILL HAVE COMMON ERROR PATTERN IN BACKEND ADD switch CONDITIONS HERE
  return [error, data];
};

/**
 * Handle error which occurred in Client Side.
 */
const handleRequestError = (error, message) => {
  return error.timeout ? API_ERRORS.timeout : API_ERRORS.common(error, message);
};

/**
 * Vb API Error Response Handler
 * @param {*} error
 * @returns
 */
export const errorResponseHandler = (error) => {
  try {
    let data = null;
    if (error.response && error.response.data) {
      // pass direct error response object from backend
      data = error.response.data;
    } else {
      // get client error if any and map client side error mapped object
      const mes = error.message || "Unknown Error";
      data = handleRequestError(error.request, mes);
    }
    const resp = vbApiResponseResolver(data, error);
    return Promise.reject(resp[1]);
  } catch (e) {
    return Promise.reject(error);
  }
};

/**
 * Success Response Handler
 * @param {*} response
 * @returns
 */
export const successResponseHandler = (response) => {
  try {
    const [error, resp] = vbApiResponseResolver(response.data);
    if (error) return Promise.reject(resp);
    return Promise.resolve(resp);
  } catch (e) {
    return Promise.resolve(response);
  }
};

/**
 * Vb APi Service Request Interceptor.
 * @param {*} config
 * @returns
 */
export const requestInterceptor = async (config) => {
  const conf = { ...config };
  const token = getCookie(AUTH_LOCAL_STORAGE_KEY, false);
  conf.baseURL = VB_API_BASE_URL;
  if (token && token !== "") conf.headers.Authorization = `Bearer ${token}`;
  return conf;
};

/**
 * All endpoints used in this Service
 */
const endpoints = {
  version: "/admin",
  dashboard: {
    index: "/dashboard",
    userChart: "/dashboard/chart/users",
    subscriptionChart: "/dashboard/chart/subscriptions",
  },
  auth: {
    login: "/auth/signin",
    verify: "/auth/health",
  },
  users: {
    list: "/users",
    update: "/users/:id",
  },
  admins: {
    list: "/admins",
    create: "/admins/create",
    details: "/admins/:id",
  },
  visions: {
    list: "/visions",
    update: "/visions/:id",
  },
  inappropriate: {
    list: "/inappropriate",
    details: "/inappropriate/:id",
    remove: "/inappropriate/:id/remove",
    related: "/inappropriate/reported/:rid/:t",
  },
  boards: {},
  uploads: {},
  notification: {
    list: "/notification",
    create: "/notification/create",
    details: "/notification/:id",
  },
  notificationActions: {
    list: "/notification-actions",
    create: "/notification-actions/create",
    details: "/notification-actions/:id",
  },
  checklist: {
    list: "/checklist",
    create: "/checklist/create",
    details: "/checklist/:id",
  },
  template: {
    list: "/email-template",
    create: "/email-template/create",
    details: "/email-template/:id",
  },
  images: {
    list: "/images",
    avatar: "/images/avatar",
    avatarDelete: "/images/avatar/delete",
  },
  imageCategory: {
    list: "/image-category",
    create: "/image-category/create",
    details: "/image-category/:id",
  },
};

/**
 * Service which will can be consume to call Vb api endpoints.
 */
class VbApiService extends HttpService {
  /**
   * Add request interceptor(handle token and any other on the fly config changes).
   * Set success and error handler & add all the possible endpoints for the service.
   */
  constructor() {
    super(requestInterceptor, successResponseHandler, errorResponseHandler, {
      timeout: 5000,
    });
    this.urls = endpoints;
  }
}

export const vbApiService = new VbApiService();
export default vbApiService;
