import { all, call } from "redux-saga/effects";
import { adminSaga } from "./ducks/admins";
import { authSaga } from "./ducks/auth";
import { checklistSaga } from "./ducks/checklist";
import { dashboardSaga } from "./ducks/dashboard";
import { imageCategorySaga } from "./ducks/imageCategory";
import { inappropriateSaga } from "./ducks/inappropriate";
import { notificationSaga } from "./ducks/notifications";
import { notificationActionsSaga } from "./ducks/notificationsActions";
import { templateSaga } from "./ducks/templates";
import { usersSaga } from "./ducks/users";
import { visionSaga } from "./ducks/visions";

/**
 * Combination of all the saga in the application
 * @returns Generator
 */
function* rootSaga() {
  yield all([
    call(authSaga),
    call(usersSaga),
    call(adminSaga),
    call(visionSaga),
    call(inappropriateSaga),
    call(notificationSaga),
    call(templateSaga),
    call(dashboardSaga),
    call(checklistSaga),
    call(imageCategorySaga),
    call(notificationActionsSaga),
  ]);
}

export default rootSaga;
