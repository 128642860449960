import produce from "immer";
import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { loginRequest, loginVerify } from "../../services/vbAPI/adapter";
import { authTypes } from "../types";

/**
 * Initial State Auth.
 */
const initialState = {
  requesting: false,
  data: null,
  verify: { requesting: false, data: null },
};

/**
 * Authentication reducer for redux
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const authReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case authTypes.loginRequest:
        draft.requesting = true;
        break;

      case authTypes.loginResponse:
        draft.requesting = false;
        draft.data = data;
        break;

      case authTypes.verifyRequest:
        draft.verify.requesting = true;
        break;

      case authTypes.verifyResponse:
        draft.verify.requesting = false;
        draft.verify.data = data;
        break;

      case authTypes.reset:
        return { ...initialState };

      default:
        break;
    }
  });

/**
 * Login Verify Request action
 * @param {*} data
 * @returns
 */
export const verifyRequestAction = (data) => ({
  type: authTypes.verifyRequest,
  data,
});

/**
 * Login Verify Response action
 * @param {*} data
 * @returns
 */
export const verifyResponseAction = (data) => ({
  type: authTypes.verifyResponse,
  data,
});

/**
 * Login Request action
 * @param {*} data
 * @returns
 */
export const loginRequestAction = (data) => ({
  type: authTypes.loginRequest,
  data,
});

/**
 * Login Response action
 * @param {*} data
 * @returns
 */
export const loginResponseAction = (data) => ({
  type: authTypes.loginResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** Login Request Saga */
  loginRequestSaga: function* ({ data }) {
    try {
      const response = yield call(loginRequest, data);
      yield put(loginResponseAction(response));
    } catch (error) {
      yield put(loginResponseAction(error));
    }
  },

  /** Verify User Request Saga */
  verifyRequestSaga: function* ({ data }) {
    try {
      const response = yield call(loginVerify, data);
      yield put(verifyResponseAction(response));
    } catch (error) {
      yield put(verifyResponseAction(error));
    }
  },
};

/**
 * Authentication saga
 */
export function* authSaga() {
  yield takeLatest(authTypes.loginRequest, sagas.loginRequestSaga);
  yield takeLeading(authTypes.verifyRequest, sagas.verifyRequestSaga);
}
