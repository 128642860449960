import "antd/dist/antd.min.css";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import ApplicationWrapper from "./app";
import "./assets/styles/style.css";
import { store } from "./state";

/**
 * Render the application
 */
createRoot(document.getElementById("root")).render(
  <StrictMode>
    <Provider store={store}>
      <ApplicationWrapper />
    </Provider>
  </StrictMode>
);
