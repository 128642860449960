import produce from "immer";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  imageCategoryCreateRequest,
  imageCategoryDeleteRequest,
  imageCategoryListRequest,
  imageCategoryUpdateRequest,
} from "../../services/vbAPI/adapter";
import { imageCategoryTypes } from "../types";

/**
 * Initial State for the admin reducer
 */
const initialState = {
  list: { requesting: true, data: null },
  create: { requesting: true, data: null },
  update: { requesting: true, data: null },
  delete: { requesting: true, data: null },
};

/**
 * imageCategory reducer for redux.
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const imageCategoryReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case imageCategoryTypes.listRequest:
        draft.list.requesting = true;
        break;

      case imageCategoryTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case imageCategoryTypes.createRequest:
        draft.create.requesting = true;
        break;

      case imageCategoryTypes.createResponse:
        draft.create.requesting = false;
        draft.create.data = data;
        break;

      case imageCategoryTypes.updateRequest:
        draft.update.requesting = true;
        break;

      case imageCategoryTypes.updateResponse:
        draft.update.requesting = false;
        draft.update.data = data;
        break;

      case imageCategoryTypes.deleteRequest:
        draft.delete.requesting = true;
        break;

      case imageCategoryTypes.deleteResponse:
        draft.delete.requesting = false;
        draft.delete.data = data;
        break;

      default:
        break;
    }
  });

/**
 * imageCategory List Request action
 * @param {*} data
 * @returns
 */
export const imageCategoryListRequestAction = (data) => {
  return {
    type: imageCategoryTypes.listRequest,
    data,
  };
};

/**
 * imageCategory List Response action
 * @param {*} data
 * @returns
 */
export const imageCategoryListResponseAction = (data) => ({
  type: imageCategoryTypes.listResponse,
  data,
});

/**
 * imageCategory create Request action
 * @param {*} data
 * @returns
 */
export const imageCategoryCreateRequestAction = (data) => ({
  type: imageCategoryTypes.createRequest,
  data,
});

/**
 * imageCategory create Response action
 * @param {*} data
 * @returns
 */
export const imageCategoryCreateResponseAction = (data) => ({
  type: imageCategoryTypes.createResponse,
  data,
});

/**
 * imageCategory update Request action
 * @param {*} data
 * @returns
 */
export const imageCategoryUpdateRequestAction = (data) => ({
  type: imageCategoryTypes.updateRequest,
  data,
});

/**
 * imageCategory Update Response action
 * @param {*} data
 * @returns
 */
export const imageCategoryUpdateResponseAction = (data) => ({
  type: imageCategoryTypes.updateResponse,
  data,
});

/**
 * Login Delete Request action
 * @param {*} data
 * @returns
 */
export const imageCategoryDeleteRequestAction = (data) => ({
  type: imageCategoryTypes.deleteRequest,
  data,
});

/**
 * Login Delete Response action
 * @param {*} data
 * @returns
 */
export const imageCategoryDeleteResponseAction = (data) => ({
  type: imageCategoryTypes.deleteResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** imageCategory List Saga */
  imageCategoryListRequest: function* ({ data }) {
    try {
      const response = yield call(imageCategoryListRequest, data);
      yield put(imageCategoryListResponseAction(response));
    } catch (error) {
      yield put(imageCategoryListResponseAction(error));
    }
  },
  /** imageCategory Create Saga */
  imageCategoryCreateRequest: function* ({ data }) {
    try {
      console.log(data);
      const response = yield call(imageCategoryCreateRequest, data);
      yield put(imageCategoryCreateResponseAction(response));
    } catch (error) {
      yield put(imageCategoryCreateResponseAction(error));
    }
  },
  /** imageCategory update Saga */
  imageCategoryUpdateRequest: function* ({ data }) {
    try {
      const response = yield call(imageCategoryUpdateRequest, data);
      yield put(imageCategoryUpdateResponseAction(response));
    } catch (error) {
      yield put(imageCategoryUpdateResponseAction(error));
    }
  },
  /** imageCategory List Saga */
  imageCategoryDeleteRequest: function* ({ data }) {
    try {
      const response = yield call(imageCategoryDeleteRequest, data);
      yield put(imageCategoryDeleteResponseAction(response));
    } catch (error) {
      yield put(imageCategoryDeleteResponseAction(error));
    }
  },
};

/** Admins saga */
export function* imageCategorySaga() {
  yield takeLeading(imageCategoryTypes.listRequest, sagas.imageCategoryListRequest);
  yield takeLeading(imageCategoryTypes.createRequest, sagas.imageCategoryCreateRequest);
  yield takeLeading(imageCategoryTypes.updateRequest, sagas.imageCategoryUpdateRequest);
  yield takeLeading(imageCategoryTypes.deleteRequest, sagas.imageCategoryDeleteRequest);
}
