import { produce } from "immer";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  dashboardDataRequest,
  dashboardUsersChartRequest
} from "../../services/vbAPI/adapter";
import { dashboardTypes } from "../types";

const initialState = {
  data: { requesting: true, data: null },
  userCharts: { requesting: true, data: null },
};

/**
 * Dashboard reducer for redux
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const dashboardReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case dashboardTypes.dataRequest:
        draft.data.requesting = true;
        break;

      case dashboardTypes.dataResponse:
        draft.data.requesting = false;
        draft.data.data = data;
        break;

      case dashboardTypes.usersChartRequest:
        draft.userCharts.requesting = true;
        break;

      case dashboardTypes.usersChartResponse:
        draft.userCharts.requesting = false;
        draft.userCharts.data = data;
        break;

      default:
        break;
    }
  });

/**
 * Dashboard Data Action request.
 * @param {*} data
 */
export const dashboardDataRequestAction = (data) => ({
  type: dashboardTypes.dataRequest,
  data,
});

/**
 * Dashboard Data Action response
 * @param {*} data
 */
export const dashboardDataResponseAction = (data) => ({
  type: dashboardTypes.dataResponse,
  data,
});

/**
 * Dashboard Data charts Action
 * @param {*} data
 */
export const dashboardUsersChartRequestAction = (data) => ({
  type: dashboardTypes.usersChartRequest,
  data,
});

/**
 * Dashboard Data charts response Action
 * @param {*} data
 */
export const dashboardUsersChartResponseAction = (data) => ({
  type: dashboardTypes.usersChartResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** Data request saga*/
  dataRequestSaga: function* ({ data }) {
    try {
      const response = yield call(dashboardDataRequest, data);
      yield put(dashboardDataResponseAction(response));
    } catch (error) {
      yield put(dashboardDataResponseAction(error));
    }
  },

  /** Get chart Data */
  usersChartSaga: function* ({ data }) {
    try {
      const response = yield call(dashboardUsersChartRequest, data);
      yield put(dashboardUsersChartResponseAction(response));
    } catch (error) {
      yield put(dashboardUsersChartResponseAction(error));
    }
  },
};

/**
 * Dashboard saga
 */
export function* dashboardSaga() {
  yield takeLeading(dashboardTypes.dataRequest, sagas.dataRequestSaga);
  yield takeLeading(dashboardTypes.usersChartRequest, sagas.usersChartSaga);
}
