import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./reducers";
import rootSaga from "./sagas";

const env = process.env.REACT_APP_ENVIRONMENT || "production";
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

/**
 * Create enhancer by using redux dev tool composer or redux compose
 */
const enhancer =
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  env === "development"
    ? composeWithDevTools
    : compose;

/**
 * Create store with reducer and enhancer with saga
 */
export const store = createStore(
  rootReducer,
  enhancer(applyMiddleware(...middleware))
);

/**
 * Run root saga which will listen to all of the actions.
 */
sagaMiddleware.run(rootSaga);

export default store;
