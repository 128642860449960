import produce from "immer";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  checklistCreateRequest,
  checklistDeleteRequest,
  checklistListRequest,
  checklistUpdateRequest,
} from "../../services/vbAPI/adapter";
import { checklistTypes } from "../types";

/**
 * Initial State for the admin reducer
 */
const initialState = {
  list: { requesting: true, data: null },
  create: { requesting: true, data: null },
  update: { requesting: true, data: null },
  delete: { requesting: true, data: null },
};

/**
 * checklists reducer for redux.
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const checklistReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case checklistTypes.listRequest:
        draft.list.requesting = true;
        break;

      case checklistTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case checklistTypes.createRequest:
        draft.create.requesting = true;
        break;

      case checklistTypes.createResponse:
        draft.create.requesting = false;
        draft.create.data = data;
        break;

      case checklistTypes.updateRequest:
        draft.update.requesting = true;
        break;

      case checklistTypes.updateResponse:
        draft.update.requesting = false;
        draft.update.data = data;
        break;

      case checklistTypes.deleteRequest:
        draft.delete.requesting = true;
        break;

      case checklistTypes.deleteResponse:
        draft.delete.requesting = false;
        draft.delete.data = data;
        break;

      default:
        break;
    }
  });

/**
 * checklist List Request action
 * @param {*} data
 * @returns
 */
export const checklistListRequestAction = (data) => {
  return {
    type: checklistTypes.listRequest,
    data,
  };
};

/**
 * checklist List Response action
 * @param {*} data
 * @returns
 */
export const checklistListResponseAction = (data) => ({
  type: checklistTypes.listResponse,
  data,
});

/**
 * checklist create Request action
 * @param {*} data
 * @returns
 */
export const checklistCreateRequestAction = (data) => ({
  type: checklistTypes.createRequest,
  data,
});

/**
 * checklist create Response action
 * @param {*} data
 * @returns
 */
export const checklistCreateResponseAction = (data) => ({
  type: checklistTypes.createResponse,
  data,
});

/**
 * checklist update Request action
 * @param {*} data
 * @returns
 */
export const checklistUpdateRequestAction = (data) => ({
  type: checklistTypes.updateRequest,
  data,
});

/**
 * checklist Update Response action
 * @param {*} data
 * @returns
 */
export const checklistUpdateResponseAction = (data) => ({
  type: checklistTypes.updateResponse,
  data,
});

/**
 * Login Delete Request action
 * @param {*} data
 * @returns
 */
export const checklistDeleteRequestAction = (data) => ({
  type: checklistTypes.deleteRequest,
  data,
});

/**
 * Login Delete Response action
 * @param {*} data
 * @returns
 */
export const checklistDeleteResponseAction = (data) => ({
  type: checklistTypes.deleteResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** checklist List Saga */
  checklistListRequest: function* ({ data }) {
    try {
      const response = yield call(checklistListRequest, data);
      yield put(checklistListResponseAction(response));
    } catch (error) {
      yield put(checklistListResponseAction(error));
    }
  },
  /** checklist Create Saga */
  checklistCreateRequest: function* ({ data }) {
    try {
      const response = yield call(checklistCreateRequest, data);
      yield put(checklistCreateResponseAction(response));
    } catch (error) {
      yield put(checklistCreateResponseAction(error));
    }
  },
  /** checklist update Saga */
  checklistUpdateRequest: function* ({ data }) {
    try {
      const response = yield call(checklistUpdateRequest, data);
      yield put(checklistUpdateResponseAction(response));
    } catch (error) {
      yield put(checklistUpdateResponseAction(error));
    }
  },
  /** checklist List Saga */
  checklistDeleteRequest: function* ({ data }) {
    try {
      const response = yield call(checklistDeleteRequest, data);
      yield put(checklistDeleteResponseAction(response));
    } catch (error) {
      yield put(checklistDeleteResponseAction(error));
    }
  },
};

/** Admins saga */
export function* checklistSaga() {
  yield takeLeading(checklistTypes.listRequest, sagas.checklistListRequest);
  yield takeLeading(checklistTypes.createRequest, sagas.checklistCreateRequest);
  yield takeLeading(checklistTypes.updateRequest, sagas.checklistUpdateRequest);
  yield takeLeading(checklistTypes.deleteRequest, sagas.checklistDeleteRequest);
}
