import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error404 from "./components/common/Status/404";
import { WithSuspenseAuth } from "./components/hocs/WithSuspenseAuth";
import DefaultLayout from "./components/layouts/default";

const LoginPage = lazy(() => import("./containers/authLogin"));
const DashboardPage = lazy(() => import("./containers/dashboard"));
const UsersPage = lazy(() => import("./containers/users"));
const AdminsPage = lazy(() => import("./containers/admins"));
const VisionsPage = lazy(() => import("./containers/visions"));
const InappropriatePage = lazy(() => import("./containers/inappropriate"));
const NotificationPage = lazy(() => import("./containers/notifications"));
const NotificationActionsPage = lazy(() =>
  import("./containers/notificationsActions")
);
const EmailTemplatePage = lazy(() => import("./containers/templates"));
const EmailAEPage = lazy(() => import("./containers/templatesAddEdit"));
const checkList = lazy(() => import("./containers/checklist"));
const ImgCat = lazy(() => import("./containers/imageCategories"));

/**
 * Application Wrapper component.
 * @returns {JSX.Element}
 */
export const ApplicationWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth/login"
          element={<WithSuspenseAuth Component={LoginPage} />}
        />

        <Route path="/" element={<DefaultLayout />}>
          <Route
            index
            element={<WithSuspenseAuth auth Component={DashboardPage} />}
          />

          <Route path="/users">
            <Route
              index
              element={<WithSuspenseAuth auth Component={UsersPage} />}
            />
          </Route>

          <Route path="/admins">
            <Route
              index
              element={<WithSuspenseAuth Component={AdminsPage} />}
            />
          </Route>

          <Route path="/visions">
            <Route
              index
              element={<WithSuspenseAuth auth Component={VisionsPage} />}
            />
          </Route>

          <Route path="/image-categories">
            <Route
              index
              element={<WithSuspenseAuth auth Component={ImgCat} />}
            />
          </Route>

          <Route path="/inappropriate">
            <Route
              index
              element={<WithSuspenseAuth auth Component={InappropriatePage} />}
            />
          </Route>

          <Route path="/notifications-actions">
            <Route
              index
              element={<WithSuspenseAuth Component={NotificationActionsPage} />}
            />
          </Route>

          <Route path="/notifications">
            <Route
              index
              element={<WithSuspenseAuth Component={NotificationPage} />}
            />
          </Route>

          <Route path="/checklist">
            <Route
              index
              DashboardPage
              element={<WithSuspenseAuth auth Component={checkList} />}
            />
          </Route>

          <Route path="/templates">
            <Route
              index
              element={<WithSuspenseAuth auth Component={EmailTemplatePage} />}
            />
            <Route
              path="add"
              element={<WithSuspenseAuth auth Component={EmailAEPage} />}
            />
            <Route
              path=":id"
              element={<WithSuspenseAuth auth Component={EmailAEPage} />}
            />
          </Route>
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default ApplicationWrapper;
