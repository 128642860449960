import {
  AuditOutlined,
  BorderInnerOutlined,
  CheckCircleOutlined,
  FileSearchOutlined,
  HomeOutlined,
  LayoutOutlined,
  NotificationFilled,
  NotificationOutlined,
  UserOutlined,
  VerifiedOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "./breadcrumbs";
import Header from "./header";
import { getLayoutSidebarNavigation, getLayoutSidebarPathToKey } from "./utils";
const { Sider, Content } = Layout;

/**
 * Default layout component
 */
const DefaultLayout = () => {
  const navigate = useNavigate();
  const $location = useLocation();

  const [selected, setSelected] = useState(["1"]);
  const [collapsed, setCollapsed] = useState(false);

  // const user = useSelector((state) => state.auth.verify);

  /**
   * Toggle Collapse menu icon
   */
  const onToggleSidebar = () => {
    setCollapsed((old) => !old);
  };

  /**
   * Match url location changed base with navigation and decide the
   * selected menu item.
   */
  useEffect(() => {
    if (!$location?.pathname) return;
    let $selected = "1";
    try {
      const match = $location?.pathname.split("/")[1] || "";
      $selected = getLayoutSidebarPathToKey(match);
    } catch (error) {
      $selected = "1";
    }
    setSelected([$selected]);
  }, [$location?.pathname]);

  /**
   * When Menu item is selected
   * @param {SelectEventHandler} info
   */
  const onSelectMenuItem = (info) => {
    setSelected([info.key]);
    const path = getLayoutSidebarNavigation(info.key);
    if (path) navigate(path);
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        onToggleSidebar={onToggleSidebar}
        isSidebarCollapsed={collapsed}
      />
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onBreakpoint={setCollapsed}
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          theme="light"
          width={276}
          style={{
            overflowY: "auto",
            padding: "20px 0 20px 0",
          }}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectable={false}
            selectedKeys={selected}
            onClick={onSelectMenuItem}
            items={[
              {
                key: "1",
                icon: <HomeOutlined />,
                label: "Dashboard",
              },
              {
                key: "2",
                icon: <UserOutlined />,
                label: "Users",
              },

              {
                key: "3",
                icon: <VerifiedOutlined />,
                label: "Admins",
              },

              {
                key: "4",
                icon: <BorderInnerOutlined />,
                label: "Visions",
              },

              {
                key: "9",
                icon: <FileSearchOutlined />,
                label: "Image Categories",
              },

              {
                key: "5",
                icon: <AuditOutlined />,
                label: "Inappropriate",
              },

              {
                key: "7",
                icon: <LayoutOutlined />,
                label: "Templates",
              },

              {
                key: "10",
                icon: <NotificationFilled />,
                label: "Notification Actions",
              },
              {
                key: "6",
                icon: <NotificationOutlined />,
                label: "Notifications",
              },
              {
                key: "8",
                icon: <CheckCircleOutlined />,
                label: "CheckList",
              },
            ]}
          />
        </Sider>
        <Content style={{ padding: "20px", overflow: "auto" }}>
          <Breadcrumbs />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
