import { AUTH_LOCAL_STORAGE_KEY, PUBLIC_URL } from "../constants";
import { deleteCookie, setCookie } from "../utils/io";

/**
 * Handle user logout
 * Single source to make user logout of the application
 * @returns void
 */
export const onLogOut = (reload = true) => {
  deleteCookie(AUTH_LOCAL_STORAGE_KEY);
  if (reload) window.location.href = `${PUBLIC_URL}/auth/login`;
};

/**
 * Handle io for authentication.
 * Single source in application to make user login
 * @param {*} token
 */
export const onLogin = (token) => {
  setCookie(AUTH_LOCAL_STORAGE_KEY, token, { days: 30 });
  window.location.href = PUBLIC_URL;
};
