import produce from "immer";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  visionsListRequest,
  visionsUpdateRequest
} from "../../services/vbAPI/adapter";
import { visionTypes } from "../types";

const initialState = {
  list: { requesting: true, data: null },
  update: { requesting: true, data: null },
};

/**
 * Visions reducer for redux
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const visionReducer = (state = initialState, { type, data }) =>
  produce(state, (draft) => {
    switch (type) {
      case visionTypes.listRequest:
        draft.list.requesting = true;
        break;

      case visionTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case visionTypes.updateRequest:
        draft.update.requesting = true;
        break;

      case visionTypes.updateResponse:
        draft.update.requesting = false;
        draft.update.data = data;
        break;

      default:
        break;
    }
  });

/**
 * Vision List Request Action
 * @param {*} data
 * @returns
 */
export const visionListRequestAction = (data) => ({
  type: visionTypes.listRequest,
  data,
});

/**
 * Vision List Response action
 * @param {*} data
 * @returns
 */
export const visionListResponseAction = (data) => ({
  type: visionTypes.listResponse,
  data,
});

/**
 * Vision Update Request Action
 * @param {*} data
 * @returns
 */
export const visionUpdateRequestAction = (data) => ({
  type: visionTypes.updateRequest,
  data,
});

/**
 * Vision Update Response action
 * @param {*} data
 * @returns
 */
export const visionUpdateResponseAction = (data) => ({
  type: visionTypes.updateResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** Vision List Request Saga */
  visionListRequest: function* ({ data }) {
    try {
      const response = yield call(visionsListRequest, data);
      yield put(visionListResponseAction(response));
    } catch (error) {
      yield put(visionListResponseAction(error));
    }
  },
  /** Vision Update Request Saga */
  visionUpdateRequest: function* ({ data }) {
    try {
      const response = yield call(visionsUpdateRequest, data);
      yield put(visionUpdateResponseAction(response));
    } catch (error) {
      yield put(visionListResponseAction(error));
    }
  },
};

/** vision saga */
export function* visionSaga() {
  yield takeLeading(visionTypes.listRequest, sagas.visionListRequest);
  yield takeLeading(visionTypes.updateRequest, sagas.visionUpdateRequest);
}
