import { LoadingOutlined } from "@ant-design/icons";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onLogOut } from "../../../helpers/auth";
import { verifyRequestAction } from "../../../state/ducks/auth";
import Error404 from "../../common/Status/404";

/**
 * With suspense and auth wrapper.
 * @param {{
 *   Component: React.ReactNode,
 *   auth: boolean,
 *   ...props : object
 * }} props
 * @returns
 */
export const WithSuspenseAuth = ({ Component, auth = false, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.verify);


  useEffect(() => {
    dispatch(verifyRequestAction());
  }, [dispatch]);

  if (!user?.data || user?.requesting) {
    return <LoadingOutlined />;
  }

  if (!user?.data?.body) return null;

  /** If No user and auth is required then show 404 */
  if (auth && !user) return <Error404 />;

  /**
   * If auth required and user is there.
   * role should be admin || super admin
   */
  if (auth && user && user?.data?.body) {
    if (
      !user?.data?.body?.role ||
      (user?.data?.body?.role !== "ADMIN" &&
        user?.data?.body?.role !== "SUPER_ADMIN")
    ) {
      onLogOut(false);
      navigate("/auth/login");
    }
  }

  return (
    <Suspense fallback={null}>
      <Component {...props} user={user} />
    </Suspense>
  );
};

export default WithSuspenseAuth;
