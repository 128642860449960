import produce from "immer";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  notificationActionsCreateRequest,
  notificationActionsDeleteRequest,
  notificationActionsListRequest,
  notificationActionsUpdateRequest,
} from "../../services/vbAPI/adapter";
import { notificationActionsTypes } from "../types";

/**
 * Initial State for the admin reducer
 */
const initialState = {
  list: { requesting: true, data: null },
  create: { requesting: true, data: null },
  update: { requesting: true, data: null },
  delete: { requesting: true, data: null },
  inappropriate: { requesting: true, data: null },
};

/**
 * Notifications reducer for redux.
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const notificationActionsReducer = (
  state = initialState,
  { type, data }
) =>
  produce(state, (draft) => {
    switch (type) {
      case notificationActionsTypes.listRequest:
        draft.list.requesting = true;
        break;

      case notificationActionsTypes.listResponse:
        draft.list.requesting = false;
        draft.list.data = data;
        break;

      case notificationActionsTypes.createRequest:
        draft.create.requesting = true;
        break;

      case notificationActionsTypes.createResponse:
        draft.create.requesting = false;
        draft.create.data = data;
        break;

      case notificationActionsTypes.updateRequest:
        draft.update.requesting = true;
        break;

      case notificationActionsTypes.updateResponse:
        draft.update.requesting = false;
        draft.update.data = data;
        break;

      case notificationActionsTypes.deleteRequest:
        draft.delete.requesting = true;
        break;

      case notificationActionsTypes.deleteResponse:
        draft.delete.requesting = false;
        draft.delete.data = data;
        break;

      default:
        break;
    }
  });

/**
 * Notification List Request action
 * @param {*} data
 * @returns
 */
export const notificationActionsListRequestAction = (data) => ({
  type: notificationActionsTypes.listRequest,
  data,
});

/**
 * Notification List Response action
 * @param {*} data
 * @returns
 */
export const notificationActionsListResponseAction = (data) => ({
  type: notificationActionsTypes.listResponse,
  data,
});

/**
 * Notification create Request action
 * @param {*} data
 * @returns
 */
export const notificationActionsCreateRequestAction = (data) => ({
  type: notificationActionsTypes.createRequest,
  data,
});

/**
 * Notification create Response action
 * @param {*} data
 * @returns
 */
export const notificationActionsCreateResponseAction = (data) => ({
  type: notificationActionsTypes.createResponse,
  data,
});

/**
 * Notification update Request action
 * @param {*} data
 * @returns
 */
export const notificationActionsUpdateRequestAction = (data) => ({
  type: notificationActionsTypes.updateRequest,
  data,
});

/**
 * Notification Update Response action
 * @param {*} data
 * @returns
 */
export const notificationActionsUpdateResponseAction = (data) => ({
  type: notificationActionsTypes.updateResponse,
  data,
});

/**
 * Login Delete Request action
 * @param {*} data
 * @returns
 */
export const notificationActionsDeleteRequestAction = (data) => ({
  type: notificationActionsTypes.deleteRequest,
  data,
});

/**
 * Login Delete Response action
 * @param {*} data
 * @returns
 */
export const notificationActionsDeleteResponseAction = (data) => ({
  type: notificationActionsTypes.deleteResponse,
  data,
});

/**
 * Sagas used in this modules
 */
const sagas = {
  /** Notification List Saga */
  notificationActionsListRequest: function* ({ data }) {
    try {
      const response = yield call(notificationActionsListRequest, data);
      yield put(notificationActionsListResponseAction(response));
    } catch (error) {
      yield put(notificationActionsListResponseAction(error));
    }
  },
  /** Notification Create Saga */
  notificationActionsCreateRequest: function* ({ data }) {
    try {
      const response = yield call(notificationActionsCreateRequest, data);
      yield put(notificationActionsCreateResponseAction(response));
    } catch (error) {
      yield put(notificationActionsCreateResponseAction(error));
    }
  },
  /** Notification update Saga */
  notificationActionsUpdateRequest: function* ({ data }) {
    try {
      const response = yield call(notificationActionsUpdateRequest, data);
      yield put(notificationActionsUpdateResponseAction(response));
    } catch (error) {
      yield put(notificationActionsUpdateResponseAction(error));
    }
  },
  /** Notification List Saga */
  notificationActionsDeleteRequest: function* ({ data }) {
    try {
      const response = yield call(notificationActionsDeleteRequest, data);
      yield put(notificationActionsDeleteResponseAction(response));
    } catch (error) {
      yield put(notificationActionsDeleteResponseAction(error));
    }
  },
};

/** Admins saga */
export function* notificationActionsSaga() {
  yield takeLeading(
    notificationActionsTypes.listRequest,
    sagas.notificationActionsListRequest
  );
  yield takeLeading(
    notificationActionsTypes.createRequest,
    sagas.notificationActionsCreateRequest
  );
  yield takeLeading(
    notificationActionsTypes.updateRequest,
    sagas.notificationActionsUpdateRequest
  );
  yield takeLeading(
    notificationActionsTypes.deleteRequest,
    sagas.notificationActionsDeleteRequest
  );
}
